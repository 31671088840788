import React, { Component } from 'react';
import './App.css';
import TabItem from './TabItem';
import Licenses from './Licenses';
import Activated from './Activated';
import btn_icon_132084 from './images/btn_icon_132084.png';
import img_elTheStack from './images/HomeScreen_elTheStack_664718.png';
import img_elLogo from './images/HomeScreen_elLogo_816790.jpg';
import Menu from './Menu';

// UI framework component imports
import Appbar from 'muicss/lib/react/appbar';

export default class AdminScreen extends Component {

  // Properties used by this component:
  // appActions, deviceInfo, name, birthYear, gender, golfDexterity, throwingHand, currentDrivingDistance, handicap, currentDriverLength, ageGroup, finishedPrograms

  constructor(props) {
    super(props);
    
    this.state = {
      selectedIndex_selector: 0,
      elLicenses_stateIndex: 0,
      menu_isOpen: false,
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  selectorSelectionChanged = (idx, ev) => {
    this.setState({selectedIndex_selector: idx});
  }
  
  onClick_elMenuButton = async (ev) => {
    this.toggle_menu();
  
  }
  
  
  toggle_menu = () => {
    this.setState({menu_isOpen: !this.state.menu_isOpen});
  }
  
  render() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.props.transitionId && this.props.transitionId.length > 0 && this.props.atTopOfScreenStack && this.props.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.props.transitionId;
    }
    if ( !this.props.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    const selectedIndex_selector = this.state.selectedIndex_selector;
    
    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      backgroundColor: '#f6f6f6',
     };
    const style_elSelector = {
      height: 'auto',  // This element is in scroll flow
     };
    // eslint-disable-next-line no-unused-vars
    const style_elSelector_part = {
      display: 'inline-block',
      position: 'relative',
      width: '91px',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    let contentElement_elLicenses;
    switch (selectedIndex_selector) {
      default:
      case 0:
        contentElement_elLicenses = (<Licenses appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />);
      break;
      case 1:
        contentElement_elLicenses = (<Activated appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />);
      break;
    }
    
    
    const style_elMenuButton = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_132084+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '89.562%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elTheStack = {
      height: 'auto',
     };
    const style_elLogo = {
      height: 'auto',
     };
    
    let cssClass_progress = 'circularProgressIndicator';
    if (this.props.appActions.isLoading()) 
      cssClass_progress += ' circularProgressIndicator-active';
    
    const style_menu = {
      pointerEvents: (this.state.menu_isOpen) ? 'auto' : 'none'
    }
    
    return (
      <div className="AppScreen AdminScreen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
            <div className="appBg" style={style_elBackground} />
          </div>
        </div>
        
        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="elSpacer">
            <div />
          </div>
          
          <div className="hasNestedComps elSelector">
            <div style={style_elSelector}>
              <div style={style_elSelector_part}  onClick={this.selectorSelectionChanged.bind(this, 0)}><TabItem visualStateIndex={this.state.selectedIndex_selector === 0 ? 1 : 0} title='Licenses' appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} /></div>
              <div style={style_elSelector_part}  onClick={this.selectorSelectionChanged.bind(this, 1)}><TabItem visualStateIndex={this.state.selectedIndex_selector === 1 ? 1 : 0} title='Activated' appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} /></div>
            </div>
          </div>
          
          <div className="hasNestedComps elLicenses">
            <div>
              {contentElement_elLicenses}
            </div>
          </div>
        </div>
        <Appbar className="navBar">
        </Appbar>
        
        <div className="screenFgContainer">
          <div className="foreground">
            <button className="actionFont elMenuButton" style={style_elMenuButton} onClick={this.onClick_elMenuButton}  />
            <img className="elTheStack" style={style_elTheStack} src={img_elTheStack} alt=""  />
            <img className="elLogo" style={style_elLogo} src={img_elLogo} alt=""  />
            <div className="elProgress">
              <svg className={cssClass_progress}>
                <circle className="circularProgress-animatedPath" style={{stroke: '#fec10d'}} cx="25" cy="25" r="17" fill="none" strokeWidth="3" strokeMiterlimit="10" />
              </svg>
            </div>
          </div>
        </div>
        <div className="drawer menu" style={style_menu} onClick={this.toggle_menu}>
          <div className={"drawerContent" + (this.state.menu_isOpen ? " drawerOpen": " drawerClosedLeft")} onClick={(e) => {e.stopPropagation();}}>
            <Menu appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} closeDrawer={this.toggle_menu} />
          </div>
        </div>
      </div>
    )
  }
  
}
